export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyC8TPoL67PkiLYNFjCTwMoF2y3RqDlMr5M',
    authDomain: 'henrikscv-d2fc3.firebaseapp.com',
    databaseURL: 'https://henrikscv-d2fc3.firebaseio.com',
    projectId: 'henrikscv-d2fc3',
    storageBucket: 'henrikscv-d2fc3.appspot.com',
    messagingSenderId: '900545843507'
  }
};
